<script>
import GameList from "@/components/GameList.vue";

export default {
    name: "gameHistory",
    components: {GameList},
    data() {
        return {
            list: []
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            let ids = JSON.parse(localStorage.getItem("MEMBER")).registerDevice
            let params = {
                ids
            }
            this.$http.get(this.host + '/game/getGameByIds', {params}).then(response => {
                let resp = response.body;
                console.log(resp)
                if (resp.code == '0000') {
                    this.list = resp.data.list
                } else {
                    this.$Message.error(resp.mesg);
                }
            });
        }
    }
}
</script>

<template>
    <div class="ting-box" style="display: flex;flex-direction: column;">
        <div style="height: 10px;width: 100%"></div>
        <GameList id="T1" :txt="'游戏记录'" :type="1" :imgBasePath="''" :gameListT1="list"></GameList>
    </div>
</template>

<style scoped lang="scss">

</style>
